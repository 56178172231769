<div id="pnlNoteBody" class="pnlNoteBody defaultWidth">
  <div id="pnlHeading" class="pnlHeading">
    <p id="lblHeading" class="lblHeading">
      Order Confirm
    </p>
  </div>
  <div id="pnlNoteContent" class="pnlNoteContent">
    <div id="pnlNoteContentInner" class="pnlNoteContentInner">
      <div id="pnlThankNote" class="pnlNote">
        <p id="lblThankNote" class="lblThankNote">
          Thank You! <br> Your Order has been placed.
        </p>
      </div>
      <div id="pnlDelNote_1" class="pnlNote">
        <p id="lblDelNote_1" class="lblDelNote">
        Your order has been placed. A Confirmation email has been sent to you.
        </p>
      </div>
      <div  class="pnlNote">
        <p id="lblDelNote_2" class="lblDelNote">
        </p>
      </div>
    </div>
  </div>
</div>