import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router'
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { ProductModel } from '../../../common/models/productModel';
import { OrderQueryModel } from '../../../common/models/orderQueryModel';
import { TrackOrderService } from '../../../services/track-order.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { SelectItemModel } from '../../../common/models/select-item.model';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit {

  currUser
  currUserName
  currUser_email
  currUser_mobile
  dialog: LsMdDialog<QueryComponent>
  dataList
  orderQueryModel: OrderQueryModel = new OrderQueryModel()
  orderQueryForm: FormGroup
  queryNames: Array<SelectItemModel> = new Array<SelectItemModel>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public formBuilder: FormBuilder,
    private trackOrderService: TrackOrderService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.populateComboData()
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.currUser.email) {
          this.currUser_email = this.currUser.email
        }
        if (this.currUser.mobile) {
          this.currUser_mobile = this.currUser.mobile
        }
        if (this.currUser) {
          if (this.currUser.fName)
            this.currUserName = this.currUser.fName
          if (this.currUser.fName && this.currUser.mName)
            this.currUserName = this.currUser.fName + ' ' + this.currUser.mName
          if (this.currUser.fName && this.currUser.mName && this.currUser.lName)
            this.currUserName = this.currUser.fName + ' ' + this.currUser.mName + ' ' + this.currUser.lName
        }
      } else if (this.activatedRoute.snapshot.queryParamMap != null) {
        this.currUser_email = this.activatedRoute.snapshot.queryParamMap.get("email").toString()
      }

      this.orderQueryForm = this.formBuilder.group({
        queryName: ["-1", [Validators.required]],
        desc: ["", [Validators.required]],
      })
    }
  }


  populateComboData() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem('-1', '---Select a Subject---');
    this.queryNames.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem('Shipment', 'Shipment');
    this.queryNames.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem('Refund', 'Refund');
    this.queryNames.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem('Cancel', 'Cancel')
    this.queryNames.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem('General', 'General')
    this.queryNames.push(selectItem);
  }

  closeDialog() {
    this.dialog.close(null);
  }

  orderQuerySubmit(event: Event) {
    this.orderQueryModel.orderId = parseInt(this.dataList.orderId);
    if (this.currUser) {
      this.orderQueryModel.userName = this.currUserName;
      this.orderQueryModel.mobileNo = this.currUser.mobile;
    } else if (this.currUser_email) {
      this.orderQueryModel.userName = this.currUser_email
    }
    if (this.orderQueryForm) {
      if(this.orderQueryForm.value.queryName == "-1"){
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Select a Query!').subscribe(response => {
        });
      }else if(this.orderQueryForm.value.queryName){
        this.orderQueryModel.queryName = this.orderQueryForm.value.queryName
        this.orderQueryModel.desc = this.orderQueryForm.value.desc;
        this.trackOrderService.getOrderQueryInfo(this.orderQueryModel, (resp) => {
          if (resp) {
            if (!resp.error) {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
                this.dialog.close(null);
              });
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
              });
            }
          }
        })
      }
    }
  }

}
