import { Component, OnInit, Inject, PLATFORM_ID, HostListener, ViewChild, OnDestroy } from '@angular/core'
import { HttpService } from '../../../services/http.service'
import { isPlatformBrowser } from '@angular/common'
import { Router, RouterEvent, ActivatedRoute, NavigationEnd } from '@angular/router'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { CategoryService } from '../../../services/category.service'
import { FilterModel } from '../../../common/models/filterModel'
import { ProductModel } from '../../../common/models/productModel'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { FiltersComponent } from '../filters/filters.component'
import { SeoService } from '../../../services/seo.service';
import { CartCountService } from '../../../services/cart-count.service';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { HomePageService } from '../../../services/home-page.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { CollectionModel } from '../../../common/models/collectionModel';
import { PartnersComponent } from '../partners/partners.component';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { ProductFilterModel } from '../../../common/models/productFilterModel';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  @ViewChild(FiltersComponent, { static: false }) filtersComponent: FiltersComponent

  navIsFixed: boolean = false;
  pageTitle: string = ""
  currType: string = "INR"
  catgName: string
  products: Array<ProductModel> = []
  currencyId
  categoryId
  parentCategoryUrl: string = '';
  pageNum
  numRecords
  divisionId
  FilterModel: Array<FilterModel> = []
  categoryList = []
  CATEGORIES: Array<{ catName: string, catId: string, catUrl: string }> = []
  catMap: Array<{ catName: string, catUrl: string }> = []
  hasMore: boolean = false
  list_View: boolean = false
  filterList: Array<FilterModel> = []
  filterId = []
  filterValueId = []
  listViewStr = "grid";
  searchResultsPage: boolean = false;
  showError: boolean = false;
  parentClass: string = "";
  qParams
  params
  route
  content: string
  cdnPathForContent: string
  showSeo: boolean = true
  errorPage: boolean = false
  errorPageUrl = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 : TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg';
  searchPageNum = 1;
  offerPageNum: number = 1;
  _cartCountSubscription;
  _wishlistSubscription;
  _routeSubscription;
  _catSubscription;
  _paramsSubscription;
  _homePageSubscription;
  forTemplate: boolean = false;
  _fetchCollectionOnUrl: boolean;
  collectionProductLinkType: number = -1;
  isCollectionUrl: boolean;
  searchKey: string;
  isAuthorPage: boolean;
  brandUrl: string;
  routerNavFlag: boolean;
  cartSettings = Constants.ONLINE_STORE_SETTING_MODEL.cartSettingsDTO;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryListPageService: CategoryListPageService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private categoryListService: CategoryListPageService,
    private httpService: HttpService,
    private seoService: SeoService,
    public cartCountService: CartCountService,
    public cartService: CartService,
    private homePageService: HomePageService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public dialogService: LsDialogService<LoginComponent>,
    private partnerDialogService: LsDialogService<PartnersComponent>
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
    }
    // let routerNavFlag = false;
    this.routerNavFlag = false;
    this.route = this.router.url;
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        this.route = newUrl;
        // routerNavFlag = true;
        this.routerNavFlag = true;
        this.urlUtility(this.routerNavFlag);
      }
    });
    if (!this.routerNavFlag) {
      this.urlUtility(this.routerNavFlag);
    }
  }

  urlUtility(routerNavFlag) {
    this.pageNum = 1;
    if (this.route.includes('collections/')) {
      this.collectionUrlUtility(routerNavFlag, this.pageNum);
    } else if (this.route.includes('search?')) {
      this.searchUrlUtility();
    } else if (this.route.includes('author/')) {
      this.authorUrlUtility();
    }
  }

  collectionUrlUtility(routerNavFlag, pageNum) {
    this.parentCategoryUrl = this.route.split("collections/")[1].split("?")[0];
    /**
    * check if string after '/collections/' is of categoryUrl or CollectionUrl.
    */
    var collectionArray: Array<CollectionModel>;
    this._homePageSubscription = this.homePageService.collectionsObservable.subscribe((data) => {
      collectionArray = data;
      if (collectionArray && collectionArray.length > 0) {
        this.isCollectionUrl = this.checkIfCollectionUrl(collectionArray);
        if (this.isCollectionUrl) {
          this.fetchCollectionOnUrl(this.parentCategoryUrl, pageNum);
        } else {
          this.collectionUtility(routerNavFlag, pageNum);
        }
      }
    });
    if (!(collectionArray && collectionArray.length > 0)) {
      var isDetailPage = false, fetchProducts = true, categoryId;
      this.homePageService.getHomePageCollectionIn(isDetailPage, fetchProducts, categoryId, (data) => {
        if (!data.error && data.data) {
          collectionArray = data.data;
          if (collectionArray && collectionArray.length > 0) {
            this.isCollectionUrl = this.checkIfCollectionUrl(collectionArray);
            if (this.isCollectionUrl) {
              this.fetchCollectionOnUrl(this.parentCategoryUrl, pageNum);
            } else {
              this.collectionUtility(routerNavFlag, pageNum);
            }
          }
        } else {
          this.collectionUtility(routerNavFlag, pageNum);
        }
      });
    }
  }

  searchUrlUtility() {
    if (this.activatedRoute.snapshot.queryParamMap != undefined && this.activatedRoute.snapshot.queryParamMap.get("k") != undefined) {
      this.getSearchData(this.searchPageNum);
      this.searchResultsPage = true;
      this.searchPageNum = 1;
    } else {
      this.showError = true;
      this.parentClass = "no-results-found";
    }
  }

  authorUrlUtility() {
    this.isAuthorPage = true;
    this.brandUrl = this.route.split("author/")[1].split("?")[0];
    this.getBrandData(1);
  }

  getBrandData(pageNum: number) {
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    let brandUrl
    if (this.router.url && this.router.url.split('author/')[1]) {
      brandUrl = this.router.url.split("author/")[1].split("?")[0];
    }
    if (this.brandUrl == undefined) {
      this.brandUrl = brandUrl;
    } else {
      if (this.brandUrl && this.brandUrl.split('collections/')[1]) {
        this.brandUrl = this.brandUrl.split("collections/")[1].split("?")[0];
      }
    }
    this.pageNum = pageNum;
    this.divisionId = 0;
    this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    this.categoryListPageService.getProductsByBrand(this.currencyId, this.brandUrl, this.pageNum, this.numRecords, this.divisionId, (resp) => {
      if (!resp.error && resp.data && resp.data.list && resp.data.list.length > 0) {
        this.errorPage = false;
        this.hasMore = resp.data.hasMore;
        if (this.pageNum == 1) {
          let productList = resp.data.list;
          this.products = productList;
        } else {
          let productList = resp.data.list;
          productList.forEach((ele) => {
            this.products.push(ele);
          })
        }
      } else {
        this.errorPage = true;
      }
    });
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
    if (this._homePageSubscription) {
      this._homePageSubscription.unsubscribe();
    }
  }

  collectionUtility(routerNavFlag, pageNum) {
    this.searchResultsPage = false;
    if (!routerNavFlag) {
      this.route = this.router.url;
      let queryParams = this.router.routerState.snapshot.root.queryParams;
      this.setQueryParams(queryParams);
      this.getFilters(this.parentCategoryUrl, pageNum);
    }
    if (!this.route.includes('?') && routerNavFlag) {
      this.getFilters(this.parentCategoryUrl, pageNum);
    }
    this._paramsSubscription = this.activatedRoute.queryParams.subscribe(data => {
      this.setQueryParams(data);
    });
  }

  checkIfCollectionUrl(collectionArray: Array<CollectionModel>): boolean {
    var found: boolean = false;
    if (collectionArray && collectionArray.length > 0) {
      for (let index = 0; index < collectionArray.length; index++) {
        var element = collectionArray[index];
        if (this.parentCategoryUrl.includes(element.collectionURL)) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  setQueryParams(queryParams) {
    if (queryParams && queryParams.setTypeData != undefined && queryParams.dataFor != undefined) {
      let parentIdx = queryParams.setTypeData;
      let currentIdx = queryParams.dataFor;
      let parentIdxArr = [];
      let currentIdArr = [];
      parentIdx.split('#').forEach(ele => {
        parentIdxArr.push(parseInt(ele));
      });
      currentIdx.split('#').forEach(ele => {
        currentIdArr.push(parseInt(ele));
      });
      this.filterId = parentIdxArr;
      this.filterValueId = currentIdArr;
    } else {
      this.filterId = [];
      this.filterValueId = [];
    }
  }

  showMore() {
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    if (this.searchResultsPage && !this.isAuthorPage) {
      this.getSearchData(this.searchPageNum + 1);
    } else if (this.isAuthorPage && !this.searchResultsPage) {
      this.getBrandData(++this.pageNum);
    } else {
      this.collectionUrlUtility(this.routerNavFlag, ++this.pageNum);
    }
  }

  fetchCollectionOnUrl(parentCategoryUrl, pageNum) {
    this._fetchCollectionOnUrl = true;
    let url = parentCategoryUrl;
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    this.offerPageNum = pageNum;
    this.categoryListPageService.fetchCollectionDetailsOnUrl(this.offerPageNum, Constants.CATEGORY_PAGE_NUM_RECORDS, url, this.currencyId, (data) => {
      if (!data.error) {
        if (data.data != null && data.data != undefined) {
          this.errorPage = false;
          let collectionData: CollectionModel;
          collectionData = data.data;
          this.pageTitle = data.data.collectionTitleName;
          this.hasMore = data.data.hasMore;
          if (collectionData.productModels != null && collectionData.productModels != undefined && collectionData.productModels.length > 0) {
            if (this.offerPageNum == 1) {
              this.products = [];
            }
            let products = collectionData.productModels;
            products.forEach(ele => {
              this.products.push(ele);
            });
          }
        }
      } else {
        this.errorPage = true;
      }
    })
  }

  getDataOnCategory(filterModel, pageNum) {
    if (this.currType != undefined) {
      this.currencyId = 1
    }
    /**
     * for nav end
     */
    let routerUrl
    if (this.router.url && this.router.url.split('collections/')[1]) {
      routerUrl = this.router.url.split("collections/")[1].split("?")[0];
    }
    if (this.parentCategoryUrl == undefined) {
      this.parentCategoryUrl = routerUrl;
    } else {
      if (this.parentCategoryUrl && this.parentCategoryUrl.split('collections/')[1]) {
        this.parentCategoryUrl = this.parentCategoryUrl.split("collections/")[1].split("?")[0];
      }
    }

    this.pageNum = pageNum;
    this.divisionId = 0;
    this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    let productFilterModel = new ProductFilterModel();
    productFilterModel.categoryUrl = this.parentCategoryUrl;
    productFilterModel.pageNum = this.pageNum;
    productFilterModel.numRecords = this.numRecords;
    productFilterModel.currencyId = this.currencyId;
    productFilterModel.divisionId = this.divisionId;
    productFilterModel.filterModels = filterModel;
    productFilterModel.productOnly = true;
    this.categoryListPageService.getDataOnCategory(productFilterModel, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, (resp) => {
      if (resp != null) {
        if (!resp.error) {
          this.errorPage = false;
          if (resp.data && resp.data.data) {
            if (resp.data.data.metaTitle && resp.data.data.metaKeyword && resp.data.data.metaDesc) {
              this.seoService.updateMetaTags(resp.data.data.metaTitle, resp.data.data.metaKeyword, resp.data.data.metaDesc)
            }
            this.cdnPathForContent = resp.data.data.seoUrl
            if (this.cdnPathForContent != undefined && this.cdnPathForContent != "") {
              this.httpService.getCDNContent(this.cdnPathForContent, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
            this.pageTitle = resp.data.data.categoryDisplayName
            this.hasMore = resp.data.hasMore
            this.catMap = []
            Object.keys(resp.data.map).forEach(ele => {
              this.catMap.push({
                catName: ele,
                catUrl: resp.data.map[ele]
              })
            })
            if (resp.data.list && resp.data.list.length > 0) {
              this.errorPage = false;
              let productList = resp.data.list
              if (this.pageNum > 1) {
                productList.forEach((ele) => {
                  this.products.push(ele);
                })
              } else {
                this.products = resp.data.list;
              }
            } else {
              this.products = []
              this.errorPage = true
            }
          } else if (resp.data == null) {
            this.products = [];
            this.errorPage = true;
          }
        } else {
          this.products = [];
          this.errorPage = true;
        }
      }
    })
  }

  navigateToCategoryUrl(url) {
    this.router.navigate(['collections/' + url]);
  }

  listView() {
    this.list_View = true
    this.listViewStr = "list"
  }

  gridView() {
    this.list_View = false
    this.listViewStr = "grid"
  }

  updateCollection(event) {
    this.FilterModel = [];
    this.FilterModel = event;
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  getFilters(categoryUrl: string, pageNum) {
    if (pageNum == 1) {
      this.filterList = [];
      this.FilterModel = [];
      this.categoryListService.getFilters(categoryUrl, resp => {
        if (resp.error) {
          this.errorPage = true;
        } else {
          this.errorPage = false;
          if (resp.data != undefined) {
            resp.data.forEach(ele => {
              this.filterList.push(ele)
            })
          }
          this.setSelectedFilters();
        }
      })
    } else {
      this.getDataOnCategory(this.FilterModel, pageNum);
    }
  }

  setSelectedFilters() {
    if (this.filterId.length != 0 && this.filterValueId.length != 0 && this.FilterModel != undefined && this.filterList != undefined) {
      for (let i = 0; i < this.filterId.length; i++) {
        let model: FilterModel = new FilterModel(); let flag = false;
        if (this.FilterModel != undefined && this.FilterModel.length > 0) {
          for (let filter = 0; filter < this.FilterModel.length; filter++) {
            if (this.FilterModel[filter].filterId == this.filterId[i]) {
              for (let j = 0; j < this.filterList.length; j++) {
                if (this.filterList[j].filterId == this.filterId[i]) {
                  for (let k = 0; k < this.filterList[j].filterModelList.length; k++) {
                    if (this.filterList[j].filterModelList[k].filterValueId == this.filterValueId[i]) {
                      this.FilterModel[filter].filterModelList.push(this.filterList[j].filterModelList[k]);
                      flag = true;
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        if (!flag) {
          for (let i1 = 0; i1 < this.filterList.length; i1++) {
            if (this.filterList[i1].filterId == this.filterId[i]) {
              for (let j1 = 0; j1 < this.filterList[i1].filterModelList.length; j1++) {
                if (this.filterList[i1].filterModelList[j1].filterValueId == this.filterValueId[i]) {
                  model.colorAvailable = this.filterList[i1].colorAvailable
                  model.filterId = this.filterList[i1].filterId
                  model.filterName = this.filterList[i1].filterName
                  model.filterType = this.filterList[i1].filterType
                  model.filterTypeId = this.filterList[i1].filterTypeId
                  model.seqNo = this.filterList[i1].seqNo;
                  model.filterModelList = new Array();
                  model.filterModelList.push(this.filterList[i1].filterModelList[j1]);
                  this.FilterModel.push(model);
                  break
                }
              }
              break
            }
          }
        }
      }
    }
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  _showFilters: boolean = false
  showFilters() {
    this._showFilters = true;
    if (this._showFilters) {
      this.showSeo = false
    } else {
      this.showSeo = true
    }
  }

  hideFiltersPanel() {
    this._showFilters = false;
    if (this._showFilters) {
      this.showSeo = false
    } else {
      this.showSeo = true
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (window.pageYOffset > 2740) {
      this.navIsFixed = false;
    } else if (number > 160) {
      this.navIsFixed = false;
    } else if (this.navIsFixed && number < 159) {
      this.navIsFixed = false;
    }
  }

  getSearchData(pageNum) {
    this.searchPageNum = pageNum
    let keyword = this.activatedRoute.snapshot.queryParamMap.get("k");
    this.searchKey = keyword;
    if (keyword != undefined && keyword != "") {
      if (isPlatformBrowser(this.platformId)) {
        if (window.sessionStorage.getItem('currencyId') != null) {
          let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
          let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
          currMap.forEach(element => {
            if (element.id == currId) {
              this.currType = element.code;
            }
          });
        }
      }
      if (this.currType != undefined) {
        this.currencyId = 1
      }
      this.categoryListPageService.searchResults(this.currencyId, keyword, pageNum, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, resp => {
        if (resp != null) {
          if (!resp.error) {
            if (resp.data && resp.data.list) {
              this.pageTitle = "Search Products for " + keyword;
              this.hasMore = resp.data.hasMore
              if (this.searchPageNum > 1) {
                let prodList = resp.data.list;
                prodList.forEach(ele => {
                  this.products.push(ele);
                })
              } else {
                this.products = resp.data.list;
              }
              this.searchResultsPage = true;
              this.parentClass = "searchResultsPage";
              this.showError = false;
            } else {
              this.showError = true;
              this.parentClass = "no-results-found";
            }
          } else {
            this.showError = true;
            this.parentClass = "no-results-found";
          }
        }
      })
    }
  }

  clearAllFilters() {
    this.FilterModel = [];
    this.filterList = [];
    this.filterId = [];
    this.filterValueId = [];
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    this.router.navigate([this.route.split("?")[0]])
    this.filtersComponent.checkRemoveAll()
  }

  remove(event) {
    if (this.FilterModel[0] != undefined && this.FilterModel[0].filterModelList.length == 0) {
      this.FilterModel = []
    }
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    this.filtersComponent.checkRemove(event.filterId, event.filterValueId)
    let len = this.filterId.length
    for (let i = 0; i < len; i++) {
      if (this.filterId[i] == event.filterId && this.filterValueId[i] == event.filterValueId) {
        this.filterId.splice(i, 1)
        this.filterValueId.splice(i, 1)
      }
    }
    this.params = {
      setTypeData: this.filterId.join('#'),
      dataFor: this.filterValueId.join('#')
    }
    if (this.filterId.length > 0) {
      this.qParams = Object.assign({}, this.params)
      this.router.navigate([this.route.split("?")[0]], { queryParams: this.qParams })
    } else {
      this.router.navigate([this.route.split("?")[0]])
    }
  }

  addToCartProduct(event) {
    /**
     * If not Login, 
     * 1. check if localCart is empty, 
     *      a)if yes, then create and update new cart and save new cart in localStorage and update observable.
     *      b)if no, then update localCart and save updated cart in localStorage and update observable.
     * If Login (
     *      1. get prevUserCart and check if localCart is empty, then create and update new cart with prevUserCart
     *         and save it in localStorage and update cartObsevable.
     *        if local is not empty, then update prevUserCart with localCart and save it in localStorage and update
     *        cartObservable.
     *  )
     * 1. check if localCart is empty, 
     *       repeat 1.a), 1.b).
     * */

    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel;
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(event);
      newProd.numCopies = 1;
      if (localStorage.getItem('localCart')) {
        localCart = JSON.parse(localStorage.getItem('localCart'));
      }
      if (window.localStorage.getItem('currentUser') !== null) {
        //logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          //update localCart
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        //compute cart
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.cartService.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              this.setLocalCart(resp.data[0], false);
              this.cartCountService.updateCart(resp.data[0]);
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        //not logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.setLocalCart(newCart, false);
          this.cartCountService.updateCart(newCart);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }

 addToWishlist(event: CartProductModel) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(event, false);
    } else {
      this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility(event, false);
        }
      });
    }
  }

addToCartWishlistUtility(event: CartProductModel, isLoginAction: boolean) {       if(!isPlatformBrowser(this.platformId)) {       return    }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(event);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(event.titleId, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
  }

 checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element = localCart.cartProductModels[index];
        if (element.titleId == id) {
          present = true;
          break;
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  getForMe() {
    this.partnerDialogService.open(PartnersComponent, {}, '').subscribe({});
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  getFilterDisplayNameList() {
    if (this.FilterModel && this.FilterModel.length > 0) {
      for (let index = 0; index < this.FilterModel.length; index++) {
        const ele = this.FilterModel[index];
        if (ele && ele.filterModelList && ele.filterModelList.length > 0) {
          for (let j = 0; j < ele.filterModelList.length; j++) {
            const element = ele.filterModelList[j];
            element.displayValue = element.filterDisplayValue;
          }
        }
      }
    }
  }

}
