<header *ngIf="!checkOutVisibility">
  <div class="header-desktop">
    <div class="pnlVendorDetailsOuter defaultWidth">
      <div class="vendor-logo float-left">
        <a [routerLink]="''" *ngIf="companyDetails">
          <img [src]="companyDetails.storeLogo != undefined?companyDetails.storeLogo:defaultPath " onError="this.src = 'assets/images/defaultLogo.png'"
            title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
        </a>
      </div>
      <div class="vendor-detail float-left">
      </div>
      <div class="right-pnl float-right">
        <div class="loginDtlsForHdr col-xs-12">
          <div class="displayifNotLoggedIn" *ngIf="!loggedIn">
            <div class="pnlTrackOrder float-right">
              <a (click)="showTrackPopOver()">Track Order</a>
            </div>
            <div class="pnlLoginDtls float-right">
              <p class="lblGuest float-right">Guest </p>
              <p class="lblHi float-right">Welcome</p>
            </div>
          </div>
        </div>
        <div class="searchPnlForHdr" [ngClass]="{'search-expanded': searchExpanded}">
          <app-search (inputFocus)="searchExpand()" (inputOutOfFocus)="searchOriginal()"></app-search>
        </div>
      </div>
    </div>
    <div class="pnlUserLinksOuter">
      <div class="pnlSearchHeader col-xs-12 no-padding">
        <!-- <div class="trackOrderLoginDtl" *ngIf="!loggedIn">
          <a id="lnk_login" class="lnk_login" (click)="goToLogin()">Login</a>
          <a id="lnk_register" class="lnk_register" (click)="goToRegistration()">Registration</a>
        </div>
        <div class="user-info" *ngIf="loggedIn">
          <div class="user-name-container">
            <p class="user-name">Welcome</p>
            <p class="lblUserName" [innerHTML]="(userState.fName !=null && userState.fName != '' )? userState.fName + '!!' : userState.userName + '!!'"></p>
          </div>
          <a class="my-account" [routerLink]="'/my-dashboard/profile'">My Account</a>
          <p class="log-out" (click)="logOut()">Logout</p>
        </div>
        <div class="pull-right cart-info" (click)="navigateToCart()">
          <span class="spnBag fa fa-lg fa-shopping-cart"></span>
          <p class="cart-count">({{cartCount}} &nbsp;items)</p>
        </div>
        <div class="app-search" [ngClass]="{'search-expanded': searchExpanded}">
          <app-search (inputFocus)="searchExpand()" (inputOutOfFocus)="searchOriginal()"></app-search>
        </div> -->
        <div class="pnlHeaderInner defaultWidth">
          <div class="pnlCartOuter" [ngClass]="{'hide': !allowCart}">
            <div class="divCartCntPnl" (click)="openCart()">
              <span class="float-left">Bag(</span>
              <span class="cartCountLbl float-left">{{cartCount}}</span>
              <span class="float-left">)</span>
            </div>
          </div>
          <div *ngIf="!loggedIn" class="pnlSignInHdr">
            <a (click)="onOpen()">Sign In</a>
          </div>
          <div class="pnlUserDetails">
            <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
              <!-- <p id="lblHi" class="lblUserName">Welcome</p> -->
              <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? userState.userName + '!!' : userState.fName + '!!'"></p>
            </div>
            <div *ngIf="loggedIn" id="pnlMyAccount" class="pnlMyAccount">
              <!-- <p id="lblMyAccnt" class="lblMyAccnt"> My Account </p> -->
              <div id="dashOptionPnl" tabindex="1">
                <a routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
                <a routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
                <a routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction
                  Log</a>
                <a routerLink="my-dashboard/change-password" routerLinkActive="active">Change
                  Password</a>
                <a routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist</a>
                <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a>
              </div>
            </div>
          </div>
          <div class="categoryPnlForHdr no-padding">
            <div class="category">
              <ul *ngIf="categoryList != undefined">
                <li *ngFor="let category of categoryList; let i = index;" class="parent-cats">
                  <div id="itrCat-0">
                    <a attr.categoryid={{category.categoryId}} class="lnkCatName mainCats" routerLink="collections/{{category.categoryURL}}">
                      <h1>{{category.categoryName}}</h1>
                    </a>
                    <ul *ngIf="category.categoryModels != undefined" class="itrCat">
                      <li *ngFor="let subCat of category.categoryModels" class="firstLevel">
                        <div id="itrCat-1">
                          <a attr.categoryid={{subCat.categoryId}} class="lnkCatName" routerLink="collections/{{subCat.categoryURL}}"
                            style="position:relative">
                            {{subCat.categoryName}}
                          </a>
                          <ul *ngIf="subCat?.categoryModels != undefined" class="secCat">
                            <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                              <div id="itrCat-2">
                                <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                  routerLink="collections/{{secLevel?.categoryURL}}">
                                  {{secLevel?.categoryName}}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>