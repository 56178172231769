<!-- <loader></loader> -->
<mat-sidenav-container class="">
  <mat-sidenav ngClass="menu-width" #accountDashboard opened="false" mode="over">
    <app-category [currencyMap]="currMap" [categoryList]="catList"></app-category>
  </mat-sidenav>
  <div fxFlexFill [class]="master">
    <app-header *ngIf="headerVisibility" (currencyMapEvent)="currMapHandler($event)" (categoryListEvent)="catgListHandler($event)"></app-header>
    <app-breadcrumbs *ngIf="breadcrumbsVisibility"></app-breadcrumbs>
    <loader></loader>
    <div class="routerDiv" [ngClass]="{'dashboard-div':isDashboard,'pos': isPos}">
      <router-outlet></router-outlet>    
    </div>
    <a class="lnkTopArrow" (click)="scrollToTop()" *ngIf="showLnkTopArrow">
      <span class="fa fa-chevron-up"></span>
    </a>    
    <app-footer *ngIf="headerVisibility"></app-footer>
  </div>
</mat-sidenav-container>