import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { TrackOrderService } from '../../../services/track-order.service';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {
  dialog:LsMdDialog<TrackOrderComponent>;  
  trackOrderForm : FormGroup
  
  constructor(
    public formBuilder : FormBuilder   ,
    private trackOrderService : TrackOrderService,
    private router :  Router 
  ) { }

  ngOnInit() {
    this.trackOrderForm = this.formBuilder.group({
      email : ["", [Validators.email, Validators.required]],
      orderId : ["", [Validators.required]]
    })
  }

  onClick(){
    this.dialog.close(null);//fns to close dialog
  }

  trackOrder(errorLabel){
    if(this.trackOrderForm.value.email ===""){
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please enter your Email-Id";      
    }else if(this.trackOrderForm.value.orderId === ""){
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please enter your Order Id";  
    }else{
      let url = "track-order?orderId="+ this.trackOrderForm.value.orderId+"&email="+this.trackOrderForm.value.email;
      location.href = url;
      // this.router.navigate([url]);
      // this.trackOrderService.trackOrder(this.trackOrderForm, resp=>{
      //   console.log(resp);
      // })
    }
  }

}