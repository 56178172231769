<footer *ngIf="!checkOutVisibility">
  <div class="footer defaultWidth">
    <div id="footer-content" class="float-left">
      <div class="footer-middle-pnl col-xs-12">
        <div class="footer-padding">
          <div class="footer-lower col-xs-12" fxLayout="row">
            <!-- <div fxShow.gt-xs fxHide.lt-sm *ngIf="categoryList != undefined" class="stores" fxLayout="column">
              <p class="heading">SHOP WITH US</p>
              <ul>
                <li *ngFor="let category of categoryList">
                  <a routerLink="collections/{{category.categoryURL}}" class="lnkStores">{{category.categoryDisplayName}}</a>
                </li>
              </ul>
            </div> -->
            <div fxShow.gt-xs fxHide.lt-sm class="account-info" fxLayout="column">
              <p class="heading">MY ACCOUNT</p>
              <div fxLayout="column">
                <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
                <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Order History</a>
                <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Transaction Logs</a>
                <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change Password</a>
                <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
              </div>
            </div>
            <div *ngIf="quickLinks != undefined" class="quicklinks" fxLayout.gt-xs="column" fxLayout.lt-sm="row">
              <p fxShow.gt-xs fxHide.lt-sm class="heading">QUICK LINKS</p>
              <ul>
                <li *ngFor="let quickLink of quickLinks | slice:0:2">
                  <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
                  <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
                </li>
                <li><a fxHide.gt-xs fxShow.lt-sm routerLink="blog" class="lnkStores" style="display:inline-block;"> Blog</a></li>
                <li><a fxHide.gt-xs fxShow.lt-sm routerLink="partners" class="lnkStores" style="display:inline-block; border-right:medium none;padding-left:10px;">Partners in Prosperity</a></li>
              </ul>
            </div>
          </div>
          <!-- <div *ngIf="socialSettings != undefined && socialSettings.fbClientKey!='' && socialSettings.fbClientSecret!=''" class="fb-widget"
            fxLayout="row" fxFlex.gt-xs="25" fxFlex.lt-sm="100">
            <iframe [attr.src]='fbWidgetSrc | safeUrl' height="280px" width="100%" allowtransparency="true" frameborder="0" scrolling="yes"></iframe>
          </div> -->
        </div>
      </div>
      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" class="footer-mb" order.lt-sm="2">
        <div *ngIf="socialSettings != undefined" class="social-share" fxFlex="50">
          <p id="socialLinksLbl" class="heading">Stay With Us</p>
          <div fxLayout="row" fxLayoutAlign.gt-xs="start center" class="col-xs-12">
            <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"><span></span></a>
            <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"><span></span></a>
            <a *ngIf="socialSettings.instagramPageURL!= ''" href={{socialSettings.instagramPageURL}} class="instagram" title="Instagram"><span></span></a>
            <a *ngIf="socialSettings.pinterestPageURL!= ''" href={{socialSettings.pinterestPageURL}} class="pinterest" title="Pinterest"><span></span></a>
            <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin" title="LinkedIn"><span></span></a>
            <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span></span></a>
          </div>
        </div>
        <div *ngIf="showNewsletter" class="newsletter" fxFlex="50">
          <p class="heading">SUBSCRIBE TO OUR NEWSLETTER</p>
          <input type="email" class="tfSubscriptionEmail" placeholder="Enter e-mail to subscribe.." (keyup)="subscribeKeyup($event, error, inputVal)"
            #inputVal/>
          <p fxShow.gt-xs fxHide.lt-sm class="send-news okLbl" (click)="subscribeNewsletter(error, inputVal)">GO</p>
          <div fxShow.lt-sm fxHide.gt-xs class="mob-send-news">
            <p class="okLbl-mob" (click)="subscribeNewsletter(error, inputVal)">GO</p>
          </div>
          <p #error class="visibility-hide errorLbl">&nbsp;</p>
        </div>
      </div>
    </div>
    <div id="contactUsFooter" class="float-left">
      <div class="contact-office" fxLayout="column">
        <p class="sub-heading heading">Contact Us</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.address}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
      </div>
      <div class="contact-call" fxLayout="column">
        <p class="sub-heading heading">Call Us</p>
        <p class="heading">{{vendorDetails.ccContactNo}}</p>
      </div>
      <div *ngIf="vendorDetails != undefined" class="contact-mail" fxLayout="column">
        <p class="sub-heading heading">Mail Us</p>
        <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
      </div>
    </div>
  </div>
  <div class="copyright" fxLayout="row">
    <div class="col-xs-12">
      <p class="copyright-txt" fxFlex="70">
        {{copyRTxt}}
      </p>
      <div class="power-by" fxLayout="row" fxLayoutAlign.gt-xs="end end" fxLayoutAlign.lt-sm="center end" fxFlex="30">
        <p fxShow.gt-xs fxHide.lt-sm class="power-txt">Powered By</p>
        <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX" alt="LSNetX"></a>
      </div>
    </div>
  </div>
</footer>