<!-- <div fxLayout="row" class="loader-main" [class.loader-hidden]="!show">
  <div fxLayout="row" fxLayout="center center" class="loader-overlay">
    <div fxLayout="row" fxFlex fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="start center" class="loader-div loader-padding">
        <div class="loader-img">
          <div *ngIf="show" fxFlexAlign="center center" class="fa  fa-lg fa-pulse fa-spinner"></div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="loader-main" [class.loader-hidden]="!show">
  <div fxLayoutAlign="center center" class="loader-overlay">
    <div class="loader-img">
      <img *ngIf="show" [src]="loaderPath">
      <!-- <div *ngIf="show" fxFlexAlign="center center" class="fa  fa-lg fa-pulse fa-spinner"></div> -->
    </div>
  </div>
</div>