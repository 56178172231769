import { Component, AfterViewInit, Input, OnDestroy, QueryList, ViewChildren, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'
import { TemplateConstants } from '../../../../published/commonComponent/template-constants';
import { interval } from 'rxjs';

export class carouselElement {
  imageUrl: string
  title: string
}

export class CoOrdinates {
  xCoOrdinate: number
  yCoOrdinate: number
}

export class DIRECTIONS {
  static readonly NO_SWIPE = 'no_swipe'
  static readonly LEFT = 'left'
  static readonly RIGHT = 'right'
  static readonly UP = 'up'
  static readonly DOWN = 'down'

}

@Component({
  selector: 'product-tile-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselElement', [
      state('in', style({ left: '0' })),
      state('left', style({ left: '-100%' })),
      state('right', style({ left: '100%' })),
      state('out', style({ left: '100%' })),
      transition('* =>  in', [animate('0.5s ease-out')]),
      transition('in =>  left', [animate('0.5s ease-in')]),
      transition('in =>  right', [animate('0.5s ease-in')])
    ])
  ]

})
export class CarouselComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {

  constructor() { }

  defaultPath = TemplateConstants.defaultImgPath;
  @Input('height') height: string;
  @Input('width') width: string;
  @Input('timeout') imageTimeout: number;
  @Input('carousel-elements') carouselElements: Array<carouselElement>;
  @Input('show-indicators') showIndicators: boolean;

  @Output('clickAction') clickAction = new EventEmitter<number>()

  interval: any;
  currentUrl: string;
  currentTitle: string
  currentClickAction: string
  currentIndex: number = 0;
  indicators = [];
  private _subscription;

  lefts: Array<string> = ['0']
  states: Array<string> = ['in']

  @ViewChildren('indicator') indicatorList;

  ngOnChanges() {
    this.lefts = ['0'];
    this.states = ['in'];
    this.indicators = [];
    this.currentIndex = 0;
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this.carouselElements && this.carouselElements.length > 0) {
      for (let i = 0; i < this.carouselElements.length; i++) {
        this.lefts.push('100%')
        this.states.push('out')
      }
      for (let i = 0; i < this.carouselElements.length; i++) {
        this.indicators.push(i);
      }
      if (this.indicatorList && this.indicatorList.length > 0) {
        let indicatorArr = this.indicatorList.toArray();
        for (var index = 0; index < indicatorArr.length; index++) {
          indicatorArr[index].nativeElement.classList.remove('selected');
          indicatorArr[index].nativeElement.classList.add('unselected');
        }
        indicatorArr[0].nativeElement.classList.remove('unselected');
        indicatorArr[0].nativeElement.classList.add('selected');
      }
      this._subscription = interval(this.imageTimeout).subscribe(x => this.slideRight());
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this._subscription != undefined) {
      this._subscription.unsubscribe();
    }
  }

  slideLeft(): void {
    //new code
    let indicatorArr = this.indicatorList.toArray();
    indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
    indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    this.states[this.currentIndex] = 'right'
    this.currentIndex = (this.currentIndex - 1 + this.carouselElements.length) % this.carouselElements.length;
    indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
    indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
    this.slideIn(this.currentIndex, 'left')
  }

  slideRight(): void {
    //new code
    let indicatorArr = this.indicatorList.toArray();
    indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
    indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    this.states[this.currentIndex] = 'left'
    this.currentIndex = (this.currentIndex + 1) % this.carouselElements.length;
    indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
    indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px';     
    this.slideIn(this.currentIndex, 'right')
  }

  pauseCarousel(): void {
    if (this._subscription)
      this._subscription.unsubscribe();
  }
  resumeCarousel(): void {
    this._subscription = interval(this.imageTimeout).subscribe(x => this.slideRight());
  }

  openImage(index): void {
    let indicatorArr = this.indicatorList.toArray();
    indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
    indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    if (index < this.currentIndex) {
      this.slideIn(index, 'left')
      this.states[this.currentIndex] = 'right'

    } else if (index > this.currentIndex) {
      this.slideIn(index, 'right')
      this.states[this.currentIndex] = 'left'

    }
    this.currentIndex = index;
    indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
    indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
  }

  slideIn(index: number, direction: 'left' | 'right'): void {
    //prepare
    if (direction === 'left') {
      this.lefts[index] = '-100%'
      this.states[index] = 'left'
    } else {
      this.lefts[index] = '100%'
      this.states[index] = 'right'

    }
    setTimeout(() => {
      this.states[index] = 'in'
    }, 100)
  }

  current: number = 0
  handleClick(index: number): void {
    this.clickAction.emit(index)
  }

  private startCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  private endCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  touchHandler(event: TouchEvent, type: string): void {
    if (type === 'start') {
      this.startCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
    } else {
      this.endCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
      this.handleCarouselSwipe()
    }

  }

  private handleCarouselSwipe(): void {
    let direction = this.xDirectionFinder()
    if (direction != DIRECTIONS.NO_SWIPE) {
      if (direction === DIRECTIONS.LEFT) {
        this.slideLeft()
      } else {
        this.slideRight()
      }
    }
  }

  private xDirectionFinder(): string {
    if (Math.abs(this.startCordinates.xCoOrdinate - this.endCordinates.xCoOrdinate) < 20)
      return DIRECTIONS.NO_SWIPE
    if (this.startCordinates.xCoOrdinate <= this.endCordinates.xCoOrdinate) {
      return DIRECTIONS.LEFT
    } else {
      return DIRECTIONS.RIGHT
    }
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}