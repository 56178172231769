import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, RouterEvent, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoginComponent } from '../../components/login/login.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { filter } from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy {
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(isPlatformBrowser(this.platformId)){
      if(window.pageYOffset == 0){
        this.showLnkTopArrow = false
      }else if(window.pageYOffset > 0){ 
        this.showLnkTopArrow = true
      }
    }
  }
  title = 'app';
  data;//data of dialog
  value;
  master: string;
  isDashboard: boolean = false;
  isPos: boolean = false;
  headerVisibility: boolean = true;
  breadcrumbsVisibility: boolean = true;
  googleAnalyticsCode = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleAnalyticsCode : '';
  @ViewChild('accountDashboard',{static: true}) accountDashboard: any;
  catList;
  currMap;
  _routeSubscription;
  _routeSubscription2;
  showLnkTopArrow: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      if(window.pageYOffset > 0){
        this.showLnkTopArrow = true
      }
    }
    Constants.MENU_BAR_NAV = this.accountDashboard;
    this._routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      if (data.url == '/' || data.url == '/' + Constants.WAR_NAME) {
        this.headerVisibility = true
        this.breadcrumbsVisibility = false
      } else if (data.url.indexOf('checkout') > -1 || data.url.indexOf('pos') > -1) {
        this.headerVisibility = false
        this.breadcrumbsVisibility = false
        if (data.url.indexOf('pos') > -1) {
          this.isPos = true
        }
      } else {
        this.headerVisibility = true
        this.breadcrumbsVisibility = true
      }
    })
    this._routeSubscription2 = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data: NavigationEnd) => {
      if (isPlatformBrowser(this.platformId)) {
        if (location.href.includes('order-confirmed') || location.href.includes('order-cancelled')
          || location.href.includes('forgot-password') || location.href.includes('track-order')) {
          this.isDashboard = true
        }
      }
    })
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._routeSubscription2) {
      this._routeSubscription2.unsubscribe();
    }
  }

  currMapHandler(event) {
    this.currMap = event
  }

  catgListHandler(event) {
    this.catList = event
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoader();
    }
    if (event instanceof NavigationEnd) {
      this.hideLoader();
    }
    if (event instanceof NavigationCancel) {
      this.hideLoader();
    }
    if (event instanceof NavigationError) {
      this.hideLoader();
    }
  }

  showLoader() {
    this.loaderService.show();
  }

  hideLoader() {
    this.loaderService.hide();
  }

  scrollToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});    
  }

}