<div id="cartWrapper" [ngClass]="{'emptyCart': emptyCart}">
    <div id="cartHeadDiv" class="cartHeadDiv">
        <p id="cartShotCrtLbl" class="lblShop">Shopping Bag</p>
        <span id="cartCloseBtn" class="fa fa-remove cartCloseBtn" (click)="onClose()">
        </span>
    </div>
    <div *ngIf="emptyCart" id="cartEmptyDiv" class="cartEmptyDiv">
        <div id="cartEmptyDivInner" class="cartEmptyDivInner">
            <p id="lblEmptyBag" class="lblEmptyBag">{{emptyCartLbl}}</p>
            <a (click)="onClose()" id="lnkGoShopping" class="lnkGoShopping">Continue Shopping</a>
        </div>
    </div>
    <div *ngIf="!emptyCart" id="cartTblTitlDiv" class="cartTblTitlDiv">
        <p id="cartTblTitlLbl_1" class="cols-xs-3 cartTblTitlLbl">Product Description</p>
        <p id="cartTblTitlLbl_2" class="cols-xs-1 cartTblTitlLbl">Quantity</p>
        <p id="cartTblTitlLbl_3" class="cols-xs-2 cartTblTitlLbl">Unit Price</p>
        <p id="cartTblTitlLbl_3" class="cols-xs-2 cartTblTitlLbl">Delivery</p>
        <p id="cartTblTitlLbl_3" class="cols-xs-2 cartTblTitlLbl">Sub Total</p>
        <p class="cols-xs-1"></p>
    </div>
    <div *ngIf="!emptyCart" class="cartItemItrDiv">
        <div *ngFor="let cartProduct of cartProducts; let cartProdIdx = index;" class="cartItmDetlsDiv">
            <div class="cartItmContainer" *ngIf="!cartProduct?.offer">
                <div class="productImageContainer cols-xs-3 inline">
                    <div class="imageDiv">
                        <a [href]="'details/'+cartProduct.productModel.productURLText+'?t='+cartProduct.productModel.titleId"
                            class="imageLink">
                            <img class="productImage img-responsive center-position" [src]="(cartProduct?.productModel?.imageUrl && cartProduct?.productModel?.images && cartProduct?.productModel?.images[0])? 
                            cartProduct?.productModel?.imageUrl +'m/'+ cartProduct?.productModel?.images[0]:defaultPath"
                                alt="" (error)="imgErrorHandler($event)">
                        </a>
                    </div>
                    <div class="detailsDiv">
                        <div id="container">
                            <a id="cartItemTtl" class="cartItemTtl " title="cartProduct.productModel.productName">{{cartProduct.productModel.productName}}</a>
                            <div class="variantWrapper">
                                <p *ngIf="cartProduct.productModel.variantAttributes != undefined" class="variant">{{setVariantName(cartProduct.productModel.variantAttributes)}}</p>
                            </div>
                            <a id="lnkWishList" class="lnkWishList" title="Add to Wishlist" *ngIf="loggedIn" (click)="moveToWishlist(cartProduct, cartProdIdx)">
                                <span id="imgWishList" class="fa fa-heart-o"></span>
                                Add To Wishlist
                            </a>
                        </div>
                    </div>
                </div>
                <div class="productQuantity cols-xs-1 inline">
                    <div fxLayoutAlign="center center" class="pnlSpinner">
                        <div fxLayout="row" class="spinner">
                            <div class="firstDiv">
                                <input value="{{cartProduct.numCopies}}" disabled />
                            </div>
                            <div fxLayout="column" class="secondDiv">
                                <div fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up"
                                    (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                                <div fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down"
                                    (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="unitPrice cols-xs-2 inline">
                    <p>{{cartProduct.productModel.mrp | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="deliveryPrice cols-xs-2 inline">
                    <p>{{cartProduct.productModel.shippingCharges | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="totalPrice cols-xs-2 inline">
                    <!-- <p>{{cartProduct.productModel.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p> -->
                    <p>{{(cartProduct.productModel.salePrice + cartProduct.productModel.gst + cartProduct.productModel.shippingCharges)
                            * cartProduct.numCopies + cartProduct.productModel.handlingCharges | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="cartDelete cols-xs-1 inline" title="Delete">
                    <span (click)='removeProduct(cartProduct, cartProdIdx)' class="cartDeleteIcon fa fa-trash">
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="bottomPnl">
        <div class="left">
            <p>{{infoLbl}}</p>
        </div>
        <div *ngIf="!emptyCart" class="cartGrandTotalDiv">
            <div class="couponAmount" *ngIf="couponApplied">
                <p class="couponDesc">{{couponApplied}} Coupon Applied</p>
                <span id="text">Coupon Amount:</span>
                <span id="value">{{couponAmount | currency:currType}}</span>
            </div>
            <!-- <div *ngIf="shippingCharges > 0" class="shippingPanel">
                <span id="text">Shipping Charges:</span>
                <span id="value">{{shippingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div>
            <div *ngIf="handlingCharges > 0" class="handlingPanel">
                <span id="text">Handling Charges:</span>
                <span id="value">{{handlingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div>
            <div class="taxesPanel">
                <span id="text">GST Charges:</span>
                <span id="value">{{taxes| currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div>
            <div class="totalSavings">
                <span id="text">Total Savings:</span>
                <span id="value">{{totalSavings | currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div> -->
            <div class="grandTotal">
                <span id="text"> Grand Total: </span>
                <span id="value">{{grandTotal | currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartFooterDiv">
        <a class="shopMore" (click)="onClose()">Back to Shopping</a>
        <a [class.hide]="!enquireVisibility" (click)="enquiryClick()" [routerLink]="'enquiry'" class="enquireNow">Enquire Now</a>
        <a (click)="proceedToCheckout()" class="checkout">Checkout</a>
    </div>
</div>