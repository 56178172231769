<div class="partners">
    <div class="heading-lbl">
      <p class="heading">Get For Me</p>
      <span class="close-pop-up fa fa-close" (click)="closeDialog()"></span>
    </div>
    <div class="form-class">
      <form enctype="multipart/form-data" [formGroup]="partnersForm" (ngSubmit)="submitPartners(errorTag)">
        <p id="subHeading">Enter Product Details</p>
        <textarea placeholder="Remarks" class="txtFieldCls" formControlName="message"></textarea>
        <input type="email" placeholder="Your Email-id" class="txtFieldCls" formControlName="email">
        <input type="text" placeholder="Your Name" class="txtFieldCls" formControlName="name">
        <div class="upload-file">
          <input type="file" class="file-upload" formControlName="file" (change)="onFileChange($event)">
        </div>
        <p class="error-label" #errorTag></p>
        <button class="submit-form">Get For Me</button>
      </form>
    </div>
  </div>