<div class="track-order">
  <span fxShow.gt-xs fxHide.lt-sm class="arrow"></span>
  <div class="track-heading ">
    <span class="track-img"></span>
    <p fxShow.lt-sm fxHide.gt-xs class="track-hdng">Track Order</p>
    <span fxShow.lt-sm fxHide.gt-xs class="close" (click)="onClick()"></span>
    <span fxShow.gt-xs fxHide.lt-sm class="close fa fa-lg fa-close" (click)="onClick()"></span>
  </div>
  <p class="error-label errorLabel" #errorLabel></p>  
  <form [formGroup]="trackOrderForm" (ngSubmit)="trackOrder(errorLabel)">
    <div fxShow.gt-xs fxHide.lt-sm class="email-panel track">
      <p class="lblEmailIdPopover">Email ID</p>
      <input type="email" class="email text-field" formControlName="email">
    </div>
    <input fxShow.lt-sm fxHide.gt-xs type="email" class="email text-field" placeholder="Email ID" formControlName="email">     
    <div fxShow.gt-xs fxHide.lt-sm class="order-id track">
      <p class="lblOrderIdPopover">Order ID</p>
      <input type="text" class="order-d text-field" formControlName="orderId">
    </div>
    <input fxShow.lt-sm fxHide.gt-xs type="text" class="order-d text-field" placeholder="Order ID" formControlName="orderId">    
    <div class="btnPnl">
      <button class="track-button float-left">Track</button>    
      <button class="track-button float-right" (click)="onClick()">Cancel</button>
    </div>
  </form>

</div>