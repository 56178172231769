<div class="mobile-pop-up">
  <div class="login">
    <div *ngIf="!loggedIn" class="login-panel">
      <span class="login-spn"></span>
      <p class="login-label" (click)="showLoginPopup()">LOGIN</p>
    </div>
    <div class="logged-panel" *ngIf="loggedIn">
      <span class="loggedin-spn"></span>
      <p class="login-label" [innerHTML]="'Hello ' + userName"></p>
    </div>
  </div>
  <div class="home">
    <span class="home-span"></span>
    <a class="home-label" (click)="closeSideNav()" [routerLink]="''">Home</a>
  </div>
  <div class="fetch-categories">
    <div class="cat-lbl show-cat" (click)="showCategories($event)">
      <span class="category-spn"></span>
      <p class="shop-label">Shop By Category</p>
      <span class="shop-label-arrow"></span>
    </div>
    <ul *ngIf="categoryList != undefined">
      <li *ngFor="let category of categoryList; let idx = index;" class="cat-li">
        <div id="itrCat-0">
          <div class="lnkCatName">
            <a attr.categoryid={{category.categoryId}} class="lnkCatName" id="lnkCatName" (click)="closeSideNav()" routerLink="collections/{{category.categoryURL}}">
              <h1>{{category.categoryName}}</h1>
            </a>
            <span *ngIf="category.categoryModels != undefined" class="mob-arrow-down" (click)="showSubCat(idx, $event)"></span>
          </div>
          <ul *ngIf="category.categoryModels != undefined" class="itrCat" #frstLevel>
            <li *ngFor="let subCat of category.categoryModels;  let indx = index">
              <div id="itrCat-1">
                <div class="lnkCatName">
                  <a attr.categoryid={{subCat.categoryId}} class="lnkCatName" id="lnkCatName" style="position:relative" (click)="closeSideNav()" routerLink="collections/{{subCat.categoryURL}}">
                          {{subCat.categoryName}}
                    </a>
                  <span *ngIf="subCat.categoryModels != undefined" class="arrow-upleft" (click)="showSecondCat(idx, indx, $event)"></span>
                </div>
                <ul *ngIf="subCat.categoryModels != undefined" class="itrCat secCat" #secLevel>
                  <li *ngFor="let secLevel of subCat.categoryModels;" class="secLevel">
                    <div id="itrCat-2">
                      <a attr.categoryid={{secLevel.categoryId}} class="lnkCatName" id="lnkCatName" (click)="closeSideNav()" routerLink="collections/{{secLevel.categoryURL}}">{{secLevel.categoryName}}</a>
                      <ul *ngIf="secLevel.categoryModels != undefined" class="itrCat thirdCat">
                        <li *ngFor="let thirdLevel of secLevel.categoryModels" class="thirdLevel">
                          <div id="itrCat-3">
                            <a attr.categoryid={{thirdLevel.categoryId}} class="lnkCatName" id="lnkCatName" (click)="closeSideNav()" routerLink="collections/{{thirdLevel.categoryURL}}">{{thirdLevel.categoryName}}</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="showCurrency" class="choose-currency">
    <div class="curr-lbl hide-cat" (click)="showCurrencyList($event)">
      <span class="currency-spn"></span>
      <p class="curr-choose">Choose Currency</p>
      <span class="curr-currency" [id]="currencyList[selectedCurrencyIndex].currencyCode"></span>
      <span class="curr-arrow"></span>      
    </div>

    <div *ngIf="currencyList != undefined">
      <ul class="currency-option" #currencyOption>
        <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
          <span [id]="model.currencyCode" class="currency-span"></span>
          <span class="curr-symbol" [innerHTML]="model.currencySymbol"></span>
          <a class="currency-value">{{model.currencyCode}}</a>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="loggedIn" class="dashboard">
    <div class="my-acc hide-cat" (click)="showAccInfo($event)">
      <span class="my-acc-span"></span>
      <p class="acnt-label">My Account</p>
      <span class="acnt-arrow"></span>      
    </div>
    <div class="acc-links">
      <a (click)="closeSideNav()" routerLink="my-dashboard/profile" class="profile">My Profile</a>
      <a (click)="closeSideNav()" routerLink="my-dashboard/orders" class="orders">My Orders</a>
      <a (click)="closeSideNav()" routerLink="my-dashboard/transactions" class="transaction">Transaction Log</a>
      <a (click)="closeSideNav()" routerLink="my-dashboard/change-password" class="change-pwd">Change Password</a>
      <a *ngIf="allowWishlist" (click)="closeSideNav()" routerLink="my-dashboard/wishlist" class="wishlist">My Wishlist</a>
      <a (click)="closeSideNav()" routerLink="my-dashboard/enquiries" class="enq">My Enquiries</a>
      <p class="log-out" (click)="logOut()">Logout</p>
    </div>

  </div>

</div>