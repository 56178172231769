<div class="catHeadingWrapper">
  <p class="catHeading">Our Offer</p>
</div>
<div class="offerWrapper">
  <div class="offer" *ngFor="let offer of offers; let idx  = index" [title]="offer?.altText">
    <img class="offerImage" *ngIf="offer?.videoURL=='' || (!offer?.videoURL)" [src]="isMobile?offer.mobilUrl:offer.imageName"
      alt="" onError="this.src = defaultPath">
    <iframe class="offerVideo" *ngIf="!(offer?.videoURL=='' || offer?.videoURL == undefined)" [src]="offer?.videoURL | safeUrl"
      frameborder="0"></iframe>
    <div fxLayoutAlign="center center" class="pnlOfferTxt" *ngIf="offer?.videoURL=='' || (!offer?.videoURL)">
      <div style="text-align:center;">
        <p class="lblOfferTxt">{{offer?.altText}}</p>
        <a class="lnkOfferTxt" (click)="navigate(offer.imageHref)">Shop Now</a>
      </div>
    </div>
  </div>
</div>